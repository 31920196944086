import React from "react";
// import "../Navbar/Navbar.css";
import linkedin from "../../src/Assets/Img/linkedin.svg";
import Facebook from "../../src/Assets/Img/Facebook.svg";
import Instagram from "../../src/Assets/Img/Instagram.svg";
import Youtube from "../../src/Assets/Img/Youtube.svg";
import Cendrol from "../../src/Assets/Img/Cendrol.svg";
import Up_Menu_Arrow from "../../src/Assets/Img/Up_Menu_Arrow.png";

const Footer = ({}) => {
  const ClickMe = () => {
    window.scroll({
      top: 0,
    });
  };
  return (
    <>
      <div className="p-5 footer   ">
        <img
          className="Up_Menu_Arrow"
          src={Up_Menu_Arrow}
          alt="..."
          onClick={() => {
            //   Scrollhome();
            ClickMe();
          }}
        />
        <div className="ms-4">
          <img src={Cendrol} alt="..." />
        </div>
        <div>
          <p className="rights mb-0">
            <span className="Version">Version - 0.2.4</span>© 2022
            www.cendrol.com All rights reserved{" "}
            <span style={{ color: "#0E0E0E", textDecoration: "underline" }}>
              <a
                href="https://cendrol.com/privacy"
                className="Privacy"
                style={{
                  color: "#0E0E0E",
                  textDecoration: "underline",
                }}
                target="_blank"
              >
                T&C, Privacy Policy
              </a>
            </span>
          </p>
        </div>
        <div>
          <a href="https://www.linkedin.com/company/cendrol/" target="_blank">
            <img className="ms-3" src={linkedin} alt="..." />
          </a>
          <a href="https://www.facebook.com/cendrolbuild" target="_blank">
            <img className="ms-3" src={Facebook} alt="..." />
          </a>
          <a
            href="https://www.instagram.com/cendrolbuild/?igshid=s0tg7esmezjo"
            target="_blank"
          >
            <img className="ms-3" src={Instagram} alt="..." />
          </a>
          <a href="https://www.youtube.com/@cendrolbuild" target="_black">
            <img className="ms-3" src={Youtube} alt="..." />
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
