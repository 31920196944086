import React, { useEffect, useRef } from "react";
import "./Blogs.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import blogs_ads from "../../Assets/Img/blogs_ads.png";
import Avatar from "@mui/material/Avatar";
// import Footer from "../../../Navbar/Footer";
// import Navbar from "../../../Navbar/Navbar";
import Carousel from "react-grid-carousel";
import BlogsCard from "../BlogsCard";
import { Link, NavLink, useNavigate } from "react-router-dom";
import DateRangeIcon from "@mui/icons-material/DateRange";
import blog_main_four from "../../Assets/Img/blog_main_four.jpg";
import blogs_img_two from "../../Assets/Img/blogs_img_two.png";
import blogs_img_three from "../../Assets/Img/blogs_img_three.jpg";
import Previous from "../../Assets/Img/Previous.png";
import Next from "../../Assets/Img/Next.png";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";

import blogs_two_main from "../../Assets/Img/blogs_two_main.jpg";
import Footer from "../Footer";

const Blogs4 = () => {
  const history = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const token = query.get("Env");

  useEffect(() => {
    // seturl(token);
    if (token) {
      localStorage.setItem("Env", token);
    }
  }, []);
  useEffect(() => {
    window.scroll({
      top: "0px",
    });
  }, []);

  // const titleRef = useRef();
  // const Scrollhome = () => {
  //   titleRef.current.scrollIntoView({ behavior: "smooth" });
  // };
  // useEffect(() => {
  //   Scrollhome();
  //   // document.getElementById("titleRef").scrollIntoView();
  //   // window.location.href = "#titleRef";
  // }, []);
  return (
    <>
      {/* <p className="titleRef" ref={titleRef}></p> */}
      <div className="border blog_main_container ">
        <div className=" Blogs_container ">
          <div
            className=" mobile_nav"
            onClick={() => {
              history("/");
            }}
          >
            {" "}
            <ArrowBackIosRoundedIcon
              fontSize="small"
              style={{ marginRight: "3px" }}
            />
            <p
              style={{ textDecoretion: "underline", margin: "10px 0px" }}
              className=" redpopup_navigation_activetext"
            >
              Back
            </p>
          </div>

          <p className="mb-0 Blogs_title ">The Only Way is Up</p>
          <div className="d-flex my-4">
            <div
              className=" Blogs_icons ps-2"
              style={{
                borderRight: "1px solid #969696",
                marginRight: "10px",
              }}
            >
              <DateRangeIcon fontSize="small" style={{ color: "#FFC107" }} />
              <p className="mb-0 Blogs_icons_text1 ms-2">Sep 18, 2022</p>
            </div>
            <div className=" Blogs_icons">
              <AccessTimeIcon fontSize="small" style={{ color: "#FFC107" }} />
              <p className="mb-0 Blogs_icons_text1  ms-2">2 min read</p>
            </div>
          </div>
          {/* img */}
          <div className="d-flex ">
            <div className="Blogs_content_container">
              <div
                className="Blogs_mainIMG "
                style={{
                  backgroundImage: `url('${blog_main_four}')`,
                }}
              ></div>
              <p className="mb-0 Blogs_mainIMG_content">
                The Leaderboard is a great way to assess one's own performance
                and recalibrate and plan accordingly when things aren't turning
                out ideally for the Partner. One can view their ranking when
                compared to their peers in the Partner App program, and thereby
                reflect or analyse on how they can improve their performance. We
                have a few simple suggestions:
                <br /> <br /> 1) Ensure that the leads given or uploaded are
                easily convertible and are pursued with the optimal enthusiasm
                to ensure that they choose Cendrol.
                <br /> 2) Leads can be briefed about Cendrol's business model
                which betters the standards set by the industry and is
                customer-centric aimed at not only being a force to reckon with
                but a positive force to be remembered!
                <br /> 3) Leads have to be looked at from an emotionally
                wholistic perspective and hence not only considered as business
                transactions but relationships that last for many years, maybe
                even generations!
                <br /> <br /> Following these guidelines will ensure that the
                conversion rate definitely improves and ultimately the rank of
                the partner will reflect the same! Go get 'em Partner!
              </p>

              <div
                className="blogs_card_container"
                style={{ marginTop: "50px", marginBottom: "50px" }}
              >
                <div
                  className="blogs_customer_card d-flex align-items-center me-4 "
                  style={{ cursor: "pointer" }}
                  onClick={() => history("/blogs3")}
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={Previous}
                    sx={{ width: 56, height: 56 }}
                  />
                  <div className="ms-2">
                    <p className="mb-0 blogs_customer_cardTitle">
                      Previous Post
                    </p>
                    <p className="mb-0 blogs_customer_cardsubTitle">
                      Journey at cendrol
                    </p>
                  </div>
                </div>
                <div
                  className="blogs_customer_card d-flex align-items-center "
                  style={{ cursor: "pointer" }}
                  onClick={() => history("/blogs1")}
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={Next}
                    sx={{ width: 56, height: 56 }}
                  />
                  <div className="ms-2">
                    <p className="mb-0 blogs_customer_cardTitle">Next Post</p>
                    <p className="mb-0 blogs_customer_cardsubTitle">
                      Partner Program
                    </p>
                  </div>
                </div>
              </div>
              <p className="mb-2 mt-4 Blogs_releted_posttext">Related Post</p>
            </div>
            <Link to="/blogs2">
              <div
                className="   blogs_ads"
                style={{
                  backgroundImage: `url('${blogs_ads}')`,
                }}
              ></div>
            </Link>
          </div>

          <Carousel
            cols={4}
            rows={1}
            gap={20}
            loop
            hideArrow={true}
            responsiveLayout={[
              {
                breakpoint: 990,
                cols: 2,
              },
              {
                breakpoint: 600,
                cols: 1,
              },
            ]}
          >
            {/* {blogs.map((blog, index) => ( */}
            <Carousel.Item>
              <NavLink to="/blogs1" className="navlinks">
                <BlogsCard
                  img_url={blogs_two_main}
                  date="Aug 10, 2022"
                  title="Partner Program"
                  content="The Partner Program is a unique tool that forms a bridge between the end consumer, Cendrol and the Partner..."
                />
              </NavLink>
            </Carousel.Item>
            <Carousel.Item>
              <NavLink to="/blogs2" className="navlinks">
                <BlogsCard
                  img_url={blogs_img_two}
                  date="Sep 8, 2022"
                  title="The Business Engine"
                  content="With our extensive Cendrol Referral Network, we ensure that our partners can triple their business..."
                />
              </NavLink>
            </Carousel.Item>
            <Carousel.Item>
              <NavLink to="/blogs3" className="navlinks">
                <BlogsCard
                  img_url={blogs_img_three}
                  date="Aug 17, 2022"
                  title="Journey at Cendrol"
                  content="From start to finish the app experience is absolute, partner -centric and designed to make it..."
                />
              </NavLink>
            </Carousel.Item>
            <Carousel.Item>
              <NavLink to="/blogs4" className="navlinks">
                <BlogsCard
                  img_url={blog_main_four}
                  date="Sep 18, 2022"
                  title="The Only Way is Up"
                  content="Each Cendrol partner has a variety of tools available for them to gauge their performance and...."
                />
              </NavLink>
            </Carousel.Item>

            {/* ))} */}
          </Carousel>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blogs4;
