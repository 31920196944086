import React, { useEffect, useRef } from "react";
import "./Blogs.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import FavoriteIcon from "@mui/icons-material/Favorite";
import blogs_ads from "../../Assets/Img/blogs_ads.png";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Avatar from "@mui/material/Avatar";
// import Footer from "../../../Navbar/Footer";
// import Navbar from "../../../Navbar/Navbar";
import Carousel from "react-grid-carousel";
import BlogsCard from "../BlogsCard";
import { Link, NavLink, useNavigate } from "react-router-dom";
import DateRangeIcon from "@mui/icons-material/DateRange";
import blogs_two_main from "../../Assets/Img/blogs_two_main.jpg";
import blogs_img_two from "../../Assets/Img/blogs_img_two.png";
import blogs_img_three from "../../Assets/Img/blogs_img_three.jpg";
import blog_main_four from "../../Assets/Img/blog_main_four.jpg";
import Previous from "../../Assets/Img/Previous.png";
import Next from "../../Assets/Img/Next.png";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import Footer from "../Footer";

const Blogs2 = () => {
  const history = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const token = query.get("Env");

  useEffect(() => {
    // seturl(token);
    if (token) {
      localStorage.setItem("Env", token);
    }
  }, []);
  useEffect(() => {
    window.scroll({
      top: "0px",
    });
  }, []);
  // const titleRef = useRef();
  // const Scrollhome = () => {
  //   titleRef.current.scrollIntoView({ behavior: "smooth" });
  // };
  // useEffect(() => {
  //   Scrollhome();
  // }, []);

  return (
    <>
      {/* <p className="titleRef" ref={titleRef}></p> */}
      <div className="border blog_main_container ">
        <div className=" Blogs_container ">
          <div
            className=" mobile_nav"
            onClick={() => {
              history("/");
            }}
          >
            {" "}
            <ArrowBackIosRoundedIcon
              fontSize="small"
              style={{ marginRight: "3px" }}
            />
            <p
              style={{ textDecoretion: "underline", margin: "10px 0px" }}
              className=" redpopup_navigation_activetext"
            >
              Back
            </p>
          </div>

          <p className="mb-0 Blogs_title ">Partner Program </p>
          <div className="d-flex my-4">
            <div
              className=" Blogs_icons ps-2"
              style={{
                borderRight: "1px solid #969696",
                marginRight: "10px",
              }}
            >
              <DateRangeIcon fontSize="small" style={{ color: "#FFC107" }} />
              <p className="mb-0 Blogs_icons_text1 ms-2">Aug 10, 2022</p>
            </div>
            <div className=" Blogs_icons">
              <AccessTimeIcon fontSize="small" style={{ color: "#FFC107" }} />
              <p className="mb-0 Blogs_icons_text1  ms-2">2 min read</p>
            </div>
          </div>
          {/* img */}
          <div className="d-flex ">
            <div className="Blogs_content_container">
              <div
                className="Blogs_mainIMG "
                style={{
                  backgroundImage: `url('${blogs_two_main}')`,
                }}
              ></div>
              <p className="mb-0 Blogs_mainIMG_content">
                The Partner Program is a unique tool that forms a bridge between
                the end consumer, Cendrol and the Partner themselves. After
                making themselves aware of Cendrol’s internal workings, from
                advertising material (brochures, flyers, etc.), and also about
                processes that are key to the pre-construction phase, the
                Partner is now equipped to be an efficient lead . They are all
                expected to be up to date with construction projects of Cendrol,
                packages and benefits given to consumers. They will also have
                access to videos and creatives to make themselves aware of the
                brands and also pitch our brand to potential leads whom they
                have forwarded so that they may lead to a closure leading to
                mutual benefits for the both of us.
              </p>

              <div
                className="blogs_card_container "
                style={{ marginTop: "50px", marginBottom: "50px" }}
              >
                <div
                  className="blogs_customer_card d-flex align-items-center me-4 "
                  style={{ cursor: "pointer" }}
                  onClick={() => history("/blogs4")}
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={Previous}
                    sx={{ width: 56, height: 56 }}
                  />
                  <div className="ms-2">
                    <p className="mb-0 blogs_customer_cardTitle">
                      Previous Post
                    </p>
                    <p className="mb-0 blogs_customer_cardsubTitle">
                      The Only Way is Up
                    </p>
                  </div>
                </div>
                <div
                  className="blogs_customer_card d-flex align-items-center "
                  style={{ cursor: "pointer" }}
                  onClick={() => history("/blogs2")}
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={Next}
                    sx={{ width: 56, height: 56 }}
                  />
                  <div className="ms-2">
                    <p className="mb-0 blogs_customer_cardTitle">Next Post</p>
                    <p className="mb-0 blogs_customer_cardsubTitle">
                      The Business Engine
                    </p>
                  </div>
                </div>
              </div>
              <p className="mb-2 mt-4 Blogs_releted_posttext">Related Post</p>
            </div>
            <Link to="/blogs2">
              <div
                className="   blogs_ads"
                style={{
                  backgroundImage: `url('${blogs_ads}')`,
                }}
              ></div>
            </Link>
          </div>

          <Carousel
            cols={4}
            rows={1}
            gap={20}
            loop
            hideArrow={true}
            responsiveLayout={[
              {
                breakpoint: 990,
                cols: 2,
              },
              {
                breakpoint: 600,
                cols: 1,
              },
            ]}
          >
            {/* {blogs.map((blog, index) => ( */}
            <Carousel.Item>
              <NavLink to="/blogs1" className="navlinks">
                <BlogsCard
                  img_url={blogs_two_main}
                  title="Partner Program"
                  date="Aug 10, 2022"
                  content="The Partner Program is a unique tool that forms a bridge between the end consumer, Cendrol and the Partner..."
                />
              </NavLink>
            </Carousel.Item>
            <Carousel.Item>
              <NavLink to="/blogs2" className="navlinks">
                <BlogsCard
                  img_url={blogs_img_two}
                  title="The Business Engine"
                  date="Sep 8, 2022"
                  content="With our extensive Cendrol Referral Network, we ensure that our partners can triple their business..."
                />
              </NavLink>
            </Carousel.Item>
            <Carousel.Item>
              <NavLink to="/blogs3" className="navlinks">
                <BlogsCard
                  img_url={blogs_img_three}
                  title="Journey at Cendrol"
                  date="Aug 17, 2022"
                  content="From start to finish the app experience is absolute, partner -centric and designed to make it..."
                />
              </NavLink>
            </Carousel.Item>
            <Carousel.Item>
              <NavLink to="/blogs4" className="navlinks">
                <BlogsCard
                  img_url={blog_main_four}
                  title="The Only Way is Up"
                  date="June 17, 2022"
                  content="Each Cendrol partner has a variety of tools available for them to gauge their performance and...."
                />
              </NavLink>
            </Carousel.Item>

            {/* ))} */}
          </Carousel>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blogs2;
