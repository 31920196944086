import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import TrendingBlogsView from "../src/Component/TrandingBlogsView";
import Blogs from "../src/Component/SingleBlogPage/Blogs";
import Blogs2 from "./Component/SingleBlogPage/Blogs2";
import Blogs3 from "./Component/SingleBlogPage/Blogs3";
import Blogs4 from "./Component/SingleBlogPage/Blogs4";

function App() {
  return (
    // <Router>
    <div className="App">
      <Routes>
        <Route path="/" element={<TrendingBlogsView />} />

        <Route path="/blogs1" element={<Blogs2 />} />
        <Route path="/blogs2" element={<Blogs3 />} />
        <Route path="/blogs3" element={<Blogs />} />
        <Route path="/blogs4" element={<Blogs4 />} />
      </Routes>
    </div>
    // </Router>
  );
}

export default App;
