import React, { useEffect, useState } from "react";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import Button from "@mui/material/Button";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import Carousel from "react-grid-carousel";
import { Link, NavLink } from "react-router-dom";
import BlogsCard from "../Component/BlogsCard";
import Reactangle from "../Assets/Img/Reactangle.jpg";
import blogs_img_two from "../Assets/Img/blogs_img_two.png";
import blogs_img_three from "../Assets/Img/blogs_img_three.jpg";
import slide_four from "../Assets/Img/slide_four.jpg";
import slide_one from "../Assets/Img/slide_one.jpg";
import slide_two from "../Assets/Img/slide_two.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import blogs_two_main from "../Assets/Img/blogs_two_main.jpg";
import blog_main_four from "../Assets/Img/blog_main_four.jpg";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";

import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import Footer from "./Footer";

const TrandingBlogsView = () => {
  // const [url , seturl]=useState("")
  const query = new URLSearchParams(window.location.search);
  const token = query.get("Env");

  useEffect(() => {
    // seturl(token);
    if (token) {
      localStorage.setItem("Env", token);
    }
  }, []);

  return (
    <div>
      <div>
        <Carousel
          cols={1}
          rows={1}
          gap={10}
          loop
          arrowLeft={
            <Button
              className="blogs_left_arrow_btn"
              variant="contained"
              startIcon={<KeyboardArrowLeftRoundedIcon />}
            >
              Prev
            </Button>
          }
          arrowRight={
            <Button
              className="blogs_right_arrow_btn"
              variant="contained"
              endIcon={<KeyboardArrowRightRoundedIcon />}
            >
              Next
            </Button>
          }
        >
          <Carousel.Item style={{ padding: "0px" }}>
            <div
              className="TrandingBlogsView_blogscontainer"
              style={{
                background: `url("${slide_one}")`,
              }}
            >
              {localStorage.getItem("Env") && (
                <div
                  className="backButton"
                  onClick={() =>
                    (window.location.href = window.location.href =
                      `https://www.partner${localStorage.getItem(
                        "Env"
                      )}.cendrol.com/home`)
                  }
                >
                  <ArrowBackIosRoundedIcon fontSize="small" />
                  <p style={{ marginBottom: "0px" }}> Back</p>
                </div>
              )}
              <div className=" blog_carousel">
                <p className="blog_carousel_text">Partner Program</p>
                <div className="  likes_container">
                  <div
                    className="likes_subcontainer"
                    style={{
                      borderRight: "1px solid #969696",
                      marginRight: "10px",
                    }}
                  >
                    <CalendarMonthIcon
                      fontSize="small"
                      style={{ color: "#FCC314" }}
                    />
                    <span className="likes_comment border_right blog_carousel_subtext ms-2">
                      Aug 10, 2022
                    </span>
                  </div>

                  <div className="likes_subcontainer">
                    <AccessTimeIcon
                      fontSize="small"
                      style={{ color: "#FFC107" }}
                    />
                    <span className="likes_comment border_right blog_carousel_subtext ms-2">
                      2 min read
                    </span>
                  </div>
                </div>
                <NavLink className="navlink" to="/blogs1">
                  <Button
                    className="Read_Full_Blog"
                    variant="contained"
                    endIcon={<ArrowCircleRightOutlinedIcon />}
                  >
                    {" "}
                    Read Full Blog
                  </Button>
                </NavLink>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item style={{ padding: "0px" }}>
            <div
              className="TrandingBlogsView_blogscontainer"
              style={{
                background: `url("${slide_two}")`,
              }}
            >
              {localStorage.getItem("Env") && (
                <div
                  className="backButton"
                  onClick={() =>
                    (window.location.href = window.location.href =
                      `https://www.partner${localStorage.getItem(
                        "Env"
                      )}.cendrol.com/home`)
                  }
                >
                  <ArrowBackIosRoundedIcon fontSize="small" />
                  <p style={{ marginBottom: "0px" }}> Back</p>
                </div>
              )}
              <div className=" blog_carousel">
                <p className="blog_carousel_text">The Business Engine</p>
                <div className="  likes_container">
                  <div
                    className="likes_subcontainer"
                    style={{
                      borderRight: "1px solid #969696",
                      marginRight: "10px",
                    }}
                  >
                    <CalendarMonthIcon
                      fontSize="small"
                      style={{ color: "#FCC314" }}
                    />
                    <span className="likes_comment border_right blog_carousel_subtext ms-2">
                      Sep 8, 2022
                    </span>
                  </div>
                  <div className="likes_subcontainer">
                    <AccessTimeIcon
                      fontSize="small"
                      style={{ color: "#FFC107" }}
                    />
                    <span className="likes_comment border_right blog_carousel_subtext  ms-2">
                      2 min read
                    </span>
                  </div>
                </div>
                <NavLink className="navlink" to="/blogs2">
                  <Button
                    className="Read_Full_Blog"
                    variant="contained"
                    endIcon={<ArrowCircleRightOutlinedIcon />}
                  >
                    Read Full Blog
                  </Button>
                </NavLink>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item style={{ padding: "0px" }}>
            <div
              className="TrandingBlogsView_blogscontainer"
              style={{
                background: `url("${Reactangle}")`,
              }}
            >
              {localStorage.getItem("Env") && (
                <div
                  className="backButton"
                  onClick={() =>
                    (window.location.href = window.location.href =
                      `https://www.partner${localStorage.getItem(
                        "Env"
                      )}.cendrol.com/home`)
                  }
                >
                  <ArrowBackIosRoundedIcon fontSize="small" />
                  <p style={{ marginBottom: "0px" }}> Back</p>
                </div>
              )}
              <div className=" blog_carousel">
                <p className="blog_carousel_text">Journey at Cendrol</p>
                <div className=" likes_container">
                  <div
                    className="likes_subcontainer"
                    style={{
                      borderRight: "1px solid #969696",
                      marginRight: "10px",
                    }}
                  >
                    <CalendarMonthIcon
                      fontSize="small"
                      style={{ color: "#FCC314" }}
                    />
                    <span className="likes_comment border_right blog_carousel_subtext ms-2">
                      Aug 17, 2022
                    </span>
                  </div>
                  <div className="likes_subcontainer">
                    <AccessTimeIcon
                      fontSize="small"
                      style={{ color: "#FFC107" }}
                    />
                    <span className="likes_comment border_right blog_carousel_subtext ms-2">
                      2 min read
                    </span>
                  </div>
                </div>
                <NavLink className="navlink" to="/blogs3">
                  <Button
                    className="Read_Full_Blog"
                    variant="contained"
                    endIcon={<ArrowCircleRightOutlinedIcon />}
                  >
                    Read Full Blog
                  </Button>
                </NavLink>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item style={{ padding: "0px" }}>
            <div
              className="TrandingBlogsView_blogscontainer"
              style={{
                background: `url("${slide_four}")`,
              }}
            >
              {localStorage.getItem("Env") && (
                <div
                  className="backButton"
                  onClick={() =>
                    (window.location.href = window.location.href =
                      `https://www.partner${localStorage.getItem(
                        "Env"
                      )}.cendrol.com/home`)
                  }
                >
                  <ArrowBackIosRoundedIcon fontSize="small" />
                  <p style={{ marginBottom: "0px" }}> Back</p>
                </div>
              )}
              <div className=" blog_carousel">
                <p className="blog_carousel_text">The Only Way is Up</p>
                <div className="  likes_container">
                  <div
                    className="likes_subcontainer"
                    style={{
                      borderRight: "1px solid #969696",
                      marginRight: "10px",
                    }}
                  >
                    <CalendarMonthIcon
                      fontSize="small"
                      style={{ color: "#FCC314" }}
                    />
                    <span className="likes_comment border_right blog_carousel_subtext ms-2">
                      Sep 18, 2022
                    </span>
                  </div>
                  <div className="likes_subcontainer">
                    <AccessTimeIcon
                      fontSize="small"
                      style={{ color: "#FFC107" }}
                    />
                    <span className="likes_comment border_right blog_carousel_subtext ms-2">
                      2 min read
                    </span>
                  </div>
                </div>
                <NavLink className="navlink" to="/blogs4">
                  <Button
                    className="Read_Full_Blog"
                    variant="contained"
                    endIcon={<ArrowCircleRightOutlinedIcon />}
                  >
                    Read Full Blog
                  </Button>
                </NavLink>
              </div>
            </div>
          </Carousel.Item>

          {/* ... */}
        </Carousel>
        {/* blogs_page data */}
        <div className="blogs_content_container">
          <p className=" Recent_Articles_txt">Recent Articles</p>
          <Carousel
            cols={4}
            rows={1}
            gap={20}
            loop
            hideArrow={true}
            responsiveLayout={[
              {
                breakpoint: 990,
                cols: 2,
              },
              {
                breakpoint: 600,
                cols: 1,
              },
            ]}
          >
            {/* {blogs.map((blog, index) => ( */}
            <Carousel.Item>
              <NavLink className="navlink" to="/blogs1">
                <BlogsCard
                  img_url={blogs_two_main}
                  title="Partner Program"
                  date="Aug 10, 2022"
                  content="The Partner Program is a unique tool that forms a bridge between the end consumer, Cendrol and the Partner..."
                />
              </NavLink>
            </Carousel.Item>
            <Carousel.Item>
              <NavLink className="navlink" to="/blogs2">
                <BlogsCard
                  img_url={blogs_img_two}
                  title="The Business Engine"
                  date="Sep 8, 2022"
                  content="With our extensive Cendrol Referral Network, we ensure that our partners can triple their business..."
                />
              </NavLink>
            </Carousel.Item>
            <Carousel.Item>
              <NavLink className="navlink" to="/blogs3">
                <BlogsCard
                  img_url={blogs_img_three}
                  date="Aug 17, 2022"
                  content="From start to finish the app experience is absolute, partner -centric and designed to make it..."
                  title="Journey at Cendrol"
                />
              </NavLink>
            </Carousel.Item>
            <Carousel.Item>
              <NavLink className="navlink" to="/blogs4">
                <BlogsCard
                  img_url={blog_main_four}
                  date="June 17, 2022"
                  title="The Only Way is Up"
                  content="Each Cendrol partner has a variety of tools available for them to gauge their performance and...."
                />
              </NavLink>
            </Carousel.Item>

            {/* ))} */}
          </Carousel>
        </div>
      </div>
      {/* mobile */}
      <Footer />
    </div>
  );
};

export default TrandingBlogsView;
