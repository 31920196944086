import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const BlogsCard = ({ title, img_url, content, date }) => {
  return (
    <>
      <Card
        style={{
          width: "100%",
          height: "100%",
          border: "1px",
          borderStyle: "solid",
          borderColor: "#E3E3E3",
        }}
      >
        <CardMedia
          component="img"
          height="150"
          image={img_url}
          alt="green iguana"
        />
        <CardContent className="blogs_Card">
          <Typography
            className="TrendingBlogs_title"
            gutterBottom
            variant="h5"
            component="div"
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            className="TrendingBlogs_subtitle "
          >
            {content}
          </Typography>
          <div className="  likes_container">
            <div
              className="likes_subcontainersingle"
              style={{ borderRight: "1px solid #969696", marginRight: "10px" }}
            >
              <DateRangeIcon
                fontSize="small"
                style={{ color: "#FCC314" }}
                className="me-2"
              />
              <span className="likes_comment border_right">{date}</span>
            </div>
            <div className="likes_subcontainersingle">
              <AccessTimeIcon fontSize="small" style={{ color: "#FFC107" }} />
              <p className="mb-0 Blogs_icons_text1  ms-2">2 min read</p>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default BlogsCard;
